import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SHOP_PAGE_TYPES } from 'Consts/shopPage';
import { ADMIN_SHOP_PAGE_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFormattedDate } from 'Utils/date';
import { getShopTypeLabel, getLagTypeLabel } from 'Utils/shopPage';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelShopPagesList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        shopPages: PropTypes.object.isRequired,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        shopType: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { location, history, shopPages } = this.props;

        return (
            <StyledComponent
                className="panel-shop-pages-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={shopPages}
                    title="Lista sklepów"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name}
                            additionals={[{
                                name: 'Data utworzenia',
                                value: getFormattedDate(element.createdAt),
                            }, {
                                name: 'Nazwa',
                                value: element.name,
                            }, {
                                name: 'Slug',
                                value: element.slug,
                            }, {
                                name: 'Typ sklepu',
                                value: getShopTypeLabel(element).label,
                            }, {
                                name: 'Wartość trwania laga',
                                value: element.lagValue,
                            }, {
                                name: 'Typ wartości trwania laga',
                                value: getLagTypeLabel(element).label,
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                to: withVariables(ADMIN_SHOP_PAGE_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }, {
                        name: 'shopType',
                        label: 'Typ sklepu',
                        type: 'select',
                        inputProps: {
                            options: [
                                {
                                    label: 'Wszystkie',
                                    value: '',
                                }, 
                                ...SHOP_PAGE_TYPES.map(option => ({ ...option, value: option.key })),
                            ],
                        },
                    }]}
                />
            </StyledComponent>
        );
    }
}
