import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_CUSTOM_EVENTS_CREATE } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import CustomEventsList from 'Components/admin/customEvents/List';

export const TAB_CUSTOM_EVENTS = 'customEvents';

export default class AdminCustomEvents extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    
    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-custom-events"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Wydarzenia niestandardowe"
                        controls={[{
                            key: 'createCustomEvent',
                            label: 'Nowe wydarzenie',
                            onClick: () => history.push(ADMIN_CUSTOM_EVENTS_CREATE.path),
                        }]}
                        tabs={[{
                            key: TAB_CUSTOM_EVENTS,
                            label: 'Lista wydarzeń',
                            children: (
                                <CustomEventsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
