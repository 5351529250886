import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_SHOP_PAGE_CREATE } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import ShopPagesList from 'Components/admin/shopPages/List';

export const TAB_SHOP_PAGES = 'shopPages';

export default class AdminShopPages extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    
    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-shop-pages"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Sklepy"
                        controls={[{
                            key: 'createShopPage',
                            label: 'Nowy sklep',
                            onClick: () => history.push(ADMIN_SHOP_PAGE_CREATE.path),
                        }]}
                        tabs={[{
                            key: TAB_SHOP_PAGES,
                            label: 'Lista sklepów',
                            children: (
                                <ShopPagesList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
