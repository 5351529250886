import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/shopPages/actions';

export default connect(
    state => ({
        shopPages: state.adminShopPages.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    })
)(Component);
